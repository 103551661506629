import $i18n from '@alife/panda-i18n';
import React, { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import { CnDraggerUpload, CnDialog, Button, CnMessage, Box, CnDownload, } from '@alife/cn-ui';
import * as _ from 'lodash';
import docCookie from 'cookie-curd';
import { wmsCnRequestV3 } from '@alife/cn-biz-utils-sc';
import ImportButton from './import-button';
import { cssPrefix } from './constant';
import LoopDialog from './loop-dialog';
import './index.scss';
import cnRequest from 'src/api';
const CnDownloadTyped = CnDownload;
export default function UploadProgress(props) {
    const { action, // 上传接口
    templateUrl, // 上传模板url
    refreshTableL2, // L2关闭上传状态浮层后刷新表格开关
    tableRef, // L2表格节点
    style, // 自定义样式
    className, // 自定义类名
    buttonProps, // 上传按钮属性
    buttonText, // 上传按钮文案
    importChildren, // 自定义按钮文案
    afterCloseUploadDialog, // 关闭浮层回调
    uploadProps: cnUploadProps, // 拖拽上传区域属性
    customUploadData, // 自定义上传返回处理
    isMenuItem, // 是否按钮组内
    accept, // 接受的文件类型
    title, // 弹窗标题
    downloadButtonText, // 下载模板文案
    onCloseCallback, // 关闭上传组件时的回调
    _getContext, // 页面数据源
    useCloseCallback, customDownloadTemplate, // 自定义下载模板
    customData = {}, // 自定义入参(静态不推荐)
    customDataFunc, // 自定义入参（动态推荐）
    beforeUpload, // 上传前回调
    beforeShowDialog, // 弹窗前钩子函数
    disabledFun, // 是否禁用
     } = props;
    const { state } = _getContext?.() || {};
    const [uploadDialogVisible, setUploadDialogVisible] = useState(false);
    const [loopDialogVisible, setLoopDialogVisible] = useState(false);
    const openUploadDialog = useCallback(() => {
        let visible = true;
        if (typeof beforeShowDialog === 'function') {
            visible = beforeShowDialog(state, props);
        }
        visible && setUploadDialogVisible(true);
    }, []);
    const [uploadData, setUploadData] = useState({});
    const [fileName, setFileName] = useState('');
    const [uploadHeader, setUploadHeader] = useState({});
    const [callbackResponse, setResponse] = useState({});
    const [customFuncData, setCustomFuncData] = useState({});
    const [disabled, setDisabled] = useState(false);
    useEffect(() => {
        const { other } = window.currentMenu || {};
        if (other && other.wmsRequestMiddleware) {
            setUploadHeader({ 'x-check-csrf-token': 1 });
            let csrfId = docCookie.getItem('x-xsrf-token') || docCookie.getItem('bigful_usuc_t');
            // 临时hack http-only的csrf cookie前端拿不到的情况
            if (!csrfId) {
                try {
                    wmsCnRequestV3?.getCsrfId?.((token) => {
                        if (token) {
                            csrfId = token;
                            setUploadHeader({ ...uploadHeader, 'x-xsrf-token': csrfId });
                        }
                    }, location.origin);
                }
                catch (e) {
                    console.error('hack http-only csrf 失败', e);
                }
            }
            setUploadHeader({ ...uploadHeader, 'x-xsrf-token': csrfId });
        }
        // customDataFunction();
        disabledStatus();
    }, []);
    const disabledStatus = async () => {
        const _disabled = disabledFun ? await disabledFun(state, props) : false;
        setDisabled(_disabled);
    };
    const onCreateJob = () => { };
    const closeUploadDialog = () => {
        clearInterval(loopTimer);
        setUploadDialogVisible(false);
    };
    let loopTimer = null;
    const onUploadSuccess = (v, v1) => {
        let response = v1?.response;
        if (v1?.status === 'done') {
            setLoopDialogVisible(true);
        }
        if (!response) {
            return;
        }
        if (customUploadData) {
            response = customUploadData(v1?.response) || {};
        }
        if (response?.success) {
            // 异步上传
            const responseSuccessHandler = (_response) => {
                if (useCloseCallback) {
                    setResponse(_response);
                    setUploadData({ status: 'SUCCEEDED' });
                    return;
                }
                if (Array.isArray(_response?.data)) {
                    setUploadData({ status: 'SUCCEEDED' });
                }
                else if (_response.data === 'success') {
                    setUploadData({ status: 'SUCCEEDED' });
                }
                else {
                    setUploadData(_response?.data);
                }
            };
            if (response?.data?.jobUrl) {
                const { jobUrl, method, params } = response?.data;
                loopTimer = setInterval(() => {
                    cnRequest({
                        url: jobUrl,
                        method,
                        data: params,
                    }).then((res) => {
                        if (res?.data?.status === 'EXECUTING') {
                            setResponse(res);
                            setUploadData({ ...res.data, status: 'EXECUTING' });
                        }
                        else if (res?.data?.status === 'SUCCEEDED') {
                            clearInterval(loopTimer);
                            responseSuccessHandler(res);
                        }
                        else {
                            clearInterval(loopTimer);
                            setUploadData({
                                jobName: v1?.name,
                                reason: res?.data?.errorMsg ||
                                    res?.data?.errorCode ||
                                    res?.error?.message ||
                                    res?.error?.code,
                                errorDownloadUrl: res?.data?.errorDownloadUrl || '',
                                progress: 0,
                                status: 'FAILED',
                            });
                        }
                    }, () => { });
                }, 2000);
                return;
            }
            responseSuccessHandler(response);
        }
        else {
            setUploadData({
                jobName: v1?.name,
                reason: response?.errorMsg ||
                    response?.errorCode ||
                    response?.error?.message ||
                    response?.error?.code,
                errorDownloadUrl: response?.errorDownloadUrl || '',
                progress: 0,
                status: 'FAILED',
            });
        }
    };
    const clearState = () => {
        clearInterval(loopTimer);
        useCloseCallback &&
            onCloseCallback &&
            onCloseCallback(state, cnRequest, callbackResponse);
        refreshTableL2 && tableRef?.load();
        afterCloseUploadDialog && afterCloseUploadDialog(props);
    };
    const customDataFunction = async () => {
        if (!customDataFunc || !_.isFunction(customDataFunc)) {
            console.log('[国内上传组件][自定义传参（动态推荐）]error: customDataFunc is not defined or not a function, please check your customDataFunc');
            return;
        }
        const data = await customDataFunc(state, props);
        if (_.isArray(data)) {
            console.log('[国内上传组件][自定义传参（动态推荐）]error: customDataFunc can not be an array, please check your customDataFunc');
            return;
        }
        if (_.isObject(data)) {
            setCustomFuncData(data);
        }
        return data;
    };
    const beforeUploadFunc = (file) => {
        customDataFunction();
        if (beforeUpload) {
            beforeUpload(file);
        }
        else {
            setFileName(file.name);
        }
    };
    return (React.createElement("div", { "data-name": 'UploadProgress', className: classNames(`${cssPrefix}wrapper`, className), style: isMenuItem ? { ...style, flexGrow: 1 } : style },
        React.createElement(ImportButton, { isMenuItem: isMenuItem, buttonProps: buttonProps, buttonText: buttonText, onClick: openUploadDialog, disabled: disabled }, importChildren && importChildren()),
        React.createElement(CnDialog, { className: `${cssPrefix}dialog`, title: title || $i18n.get({ id: 'Import', dm: '导入' }), visible: uploadDialogVisible, onOk: onCreateJob, okProps: { visible: false }, cancelProps: { visible: false }, onClose: closeUploadDialog, cache: false, footer: React.createElement(Button, { onClick: closeUploadDialog }, $i18n.get({ id: 'Cancel', dm: '取消' })) },
            React.createElement("div", { className: `${cssPrefix}content-wrapper` },
                templateUrl ? (React.createElement("div", { className: `${cssPrefix}message-slot` },
                    React.createElement(CnMessage, { type: 'notice' },
                        ' ',
                        React.createElement(Box, { direction: 'row', align: 'center' },
                            $i18n.get({ id: 'PleaseDownload', dm: '请下载' }),
                            React.createElement(CnDownloadTyped
                            // 颜色需要区分明显，需求来源浅思和黑绮
                            , { 
                                // 颜色需要区分明显，需求来源浅思和黑绮
                                buttonProps: {
                                    text: true,
                                    type: 'primary',
                                    component: 'a',
                                    className: 'download-template-button',
                                }, buttonText: downloadButtonText ||
                                    $i18n.get({
                                        id: 'ImportFormatTemplateXlxs',
                                        dm: '导入格式模板.xlxs',
                                    }), url: customDownloadTemplate
                                    ? _.get(state, customDownloadTemplate)
                                    : templateUrl }),
                            $i18n.get({
                                id: 'StandardFormatTemplate',
                                dm: '标准格式模板',
                            }))))) : null,
                React.createElement(CnDraggerUpload, { className: `${cssPrefix}upload`, shape: 'dragger', ...cnUploadProps, multiple: false, limit: 1, onChange: onUploadSuccess, action: action, onError: (_unuse, res, task) => {
                        const msg = res?.errorMsg || res?.error?.message || res?.error?.code;
                        if (msg) {
                            setUploadData({
                                jobName: task?.name,
                                reason: msg,
                                errorDownloadUrl: res?.errorDownloadUrl || '',
                                progress: 0,
                                status: 'FAILED',
                            });
                            setLoopDialogVisible(true);
                        }
                    }, description: cnUploadProps?.description ||
                        $i18n.get({
                            id: 'LimitedToMBOnlyInPng_854334921',
                            dm: '限制50mb以内，仅png、jpg格式；限制上传5个文件以内。',
                        }), accept: accept || '*', beforeUpload: beforeUploadFunc, data: { name: fileName, ...customFuncData, ...customData }, headers: uploadHeader }))),
        uploadData ? (React.createElement(LoopDialog, { onRender: closeUploadDialog, visible: loopDialogVisible, setVisible: setLoopDialogVisible, onClose: clearState, data: uploadData })) : null));
}
export { UploadProgress };
UploadProgress.displayName = 'UploadProgress';
