import React, { useState, useEffect, useMemo, useRef } from 'react';
import cnRequest from 'src/api';
import { componentName } from './const';

import './index.scss';

function DefaultComponent() {}

function htmlDecode(str) {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = str;
  return textarea.value;
}

const RuleConfigPreview = (props) => {
  const {
    _uuid,
    value,
    onChange,
    url = 'https://oneapi.alibaba-inc.com/mock/lc7bfx9k/api/config-preview/mock',
    _getContext,
  } = props;
  const { state } = _getContext?.() || {};
  const isEdit = useMemo(() => {
    return location.href.includes('cone.cainiao-inc.com/lowcode/edit');
  }, []);
  const [schema, setSchema] = useState(null);
  const thisRef = useRef(null);
  const onChangeRef = useRef(null);
  onChangeRef.current = onChange;
  const { Block = DefaultComponent } = useMemo(() => {
    return window.ConeRenderEngine || parent.ConeRenderEngine || {};
  }, []);

  const pageProps = useMemo(() => {
    return {
      value: {
        _uuid,
        ...value,
      },
      onChange: (v) => {
        onChangeRef.current?.({ ...v });
      },
    };
  }, [_uuid, value]);

  useEffect(() => {
    cnRequest({
      url: typeof url === 'function' ? url(state) : url,
      method: 'get',
    }).then((res) => {
      let data = res?.data || '{}';

      if (typeof data === 'string') {
        const decodedStr = htmlDecode(data);
        data = JSON.parse(decodedStr);
      }

      setSchema(data);
    });
  }, [state, url]);

  if (!schema) {
    return <div>需要配置规则url</div>;
  }

  return (
    <div className={`custom-config-preview ${isEdit ? 'editing' : ''}`}>
      {isEdit ? (
        <div>规则组件({typeof url === 'function' ? url(state) : url})</div>
      ) : (
        <Block
          // key={keyRef.current}
          id={_uuid}
          schema={schema}
          pageProps={pageProps}
          onSaveThis={(_this) => {
            if (!thisRef.current) {
              thisRef.current = _this;
            }
          }}
          // eslint-disable-next-line
          // onBeforeRender={async (
          //   oldSchema: unknown,
          // ): Promise<{ schema: unknown; pageProps?: unknown }> => {
          //   try {
          //     if (
          //       props?.enableBeforeRenderHook &&
          //       window?.onConeSchemaBeforeRender
          //     ) {
          //       // 钩子函数，返回对象结构，{ schema, pageProps }
          //       return (await window?.onConeSchemaBeforeRender({
          //         schema: oldSchema,
          //         props,
          //         extraSmartData,
          //       })) as {
          //         schema: any;
          //         pageProps?: any;
          //       };
          //     }
          //   } catch (err) {
          //     console.log('onBeforeRender error', err);
          //   }
          //   // eslint-disable-next-line
          //   return { schema: oldSchema };
          // }}
          // onPageError={onPageError}
          // 设置true，仅 onPageError 上报，Block 组件内部不上报
          onlyOnPageError
        />
      )}
    </div>
  );
};

RuleConfigPreview.displayName = componentName;

export { RuleConfigPreview };
