import $i18n from '@alife/panda-i18n';
import React, { useRef, useState, useEffect, useMemo, useCallback, } from 'react';
import classnames from 'classnames';
import { CnButton, CnDialog, CnInput, CnMessage, CnBalloon, Form, Field, CnFormItem, CnCheckbox, CnAsyncSelect, } from '@alife/cn-ui';
import { wmsCnRequestV3 } from '@alife/cn-biz-utils-sc';
import { domain as domainEnv, getDefaultFileName, getExportOfflineFilter, File_Name_Map, } from './utils';
import { componentName } from './const';
import { Sortable } from '../sortable';
import { getExportColumns, getUserSet, getTemplateList, editTemplate, deleteTemplate, qlDomain, } from './api';
import './index.scss';
function ExportNew(props) {
    const { name: propsName, selectedRowRecords = [], selectedRowKeys, _bindFilterData, params, className, popupClassName, children, footerActions = ['cancel', 'ok'], taskCenterUrl: taskUrl = `${qlDomain}page-next/exportTasks`, customExportFn, progressData, preCheck, csrfToken, isNeedCsrf, isJump2History, primaryKey = '', requestOptions, csrfUrl, tip, isMenuItem = false, paramsJsx, _state, size: dsize = 'small', popupContainer, 
    // 导出v2低码-专用配置项兼容
    v2ConfigJsx, buttonConfig, } = props;
    // 增加 exportMode\version\domain, 表示：主单/明细、新版本、页面用于名称
    let { 
    // 导出v2源码-版本
    version = '', 
    // 导出v2源码-开启列设置
    exportCloumnSet, 
    // 导出v2源码-按钮唯一区分key https://alidocs.dingtalk.com/i/nodes/r1R7q3QmWew5lo02fogk4vvbJxkXOEP2?utm_scene=person_space
    domain = '', // 也是processType
    metricsType = 'Drill', // 查询类型 魔方解释
    functionType, // 功能位置类型 魔方解释 0：页面，1：导出，2：详情导出，3：离线导出，4：离线详情导出
    // 导出v2源码-导出明细主单/明细
    exportMode, 
    // 导出v2源码-导出默认文件名称
    domain_cn = File_Name_Map[domain], 
    // 导出url
    exportUrl = `${domainEnv.getDomain('data')}/api/zreport/user/exportdata`, 
    // 包裹data
    isNeedDataWrapper = true, barCode2ItemIdUrl, barCode2ItemIdKey, barCode2ItemIdTargetKey, 
    // 选择行导出，放由前端来实现
    rowSelectKey, rowSelect2FilterKey, } = props;
    const { buttonHoverInfo, disabled } = buttonConfig || {};
    const tipContent = useMemo(() => {
        // 12版本tip不会展示，临时hack
        if (window.CN_UI_VERSION.indexOf('0.12.') !== 0) {
            return tip;
        }
        let content = buttonHoverInfo?.hoverInfo?.content;
        if (typeof content === 'function') {
            content = content({}, _state, selectedRowKeys, selectedRowRecords);
        }
        return tip || content || null;
    }, [buttonHoverInfo, tip, _state, selectedRowKeys, selectedRowRecords]);
    // 兼容低码配置项
    if (v2ConfigJsx) {
        try {
            const v2Props = v2ConfigJsx?.();
            const { pageCode, isShowCloumnSet, defaultExportFileName, isExportDetail, url, isNeedDataWrapper: v2IsNeedDataWrapper, metricsType: v2MetricsType, functionType: v2FunctionType, } = v2Props || {};
            if (Object.keys(v2ConfigJsx?.() || {}).length > 0) {
                // 认为配置了低码兼容配置项，就是V2版本
                version = 'V2';
            }
            if (isShowCloumnSet === true) {
                exportCloumnSet = isShowCloumnSet;
            }
            pageCode && (domain = pageCode);
            v2MetricsType && (metricsType = v2MetricsType);
            v2FunctionType && (functionType = v2FunctionType);
            url && (exportUrl = url);
            isExportDetail ? (exportMode = 2) : (exportMode = 1);
            if (typeof v2IsNeedDataWrapper !== 'undefined') {
                isNeedDataWrapper = v2IsNeedDataWrapper;
            }
            defaultExportFileName && (domain_cn = defaultExportFileName);
            // 导出v2源码-导出默认文件名称
            // 针对多货库存新增，将商品条码转为itemId, 解决zereport不支持的问题，当其他页面再需要时，再搞通用
            v2Props.barCode2ItemIdUrl &&
                (barCode2ItemIdUrl = v2Props.barCode2ItemIdUrl);
            v2Props.barCode2ItemIdKey &&
                (barCode2ItemIdKey = v2Props.barCode2ItemIdKey);
            v2Props.rowSelectKey && (rowSelectKey = v2Props.rowSelectKey);
            v2Props.rowSelect2FilterKey &&
                (rowSelect2FilterKey = v2Props.rowSelect2FilterKey);
            v2Props.barCode2ItemIdTargetKey &&
                (barCode2ItemIdTargetKey = v2Props.barCode2ItemIdTargetKey);
        }
        catch (e) {
            console.error('低码解析V2导出失败', e);
        }
    }
    console.log('exportNew', props);
    // 兼容任务、计划单、等离线导出模板  ---- 这段逻辑先不要用，确保配置正确，这里兜底有问题; exportMode是原来接入离线使用的，未来不再使用
    if (exportMode && typeof functionType === 'undefined') {
        if (exportCloumnSet === true) {
            // 离线导出
            functionType = exportMode === 1 ? 3 : 4;
        }
        else {
            // 实时导出
            functionType = exportMode === 2 ? 0 : 2;
        }
    }
    const name = children || propsName || $i18n.get({ id: 'Export', dm: '导出' });
    const container = popupContainer ? { popupContainer } : {};
    const setName = ['导出', '导出', '详情导出', '离线导出', '离线详情导出'][functionType || 0] || '导出';
    const [visible, setVisible] = useState(false);
    // const [dsize, setDsize] = useState(size);
    const [loading, setLoading] = useState(false);
    const [exportColumns, setExportColumns] = useState([]);
    // const [showSetDiv, setShowSetDiv] = useState(false);
    const [selectNum, setSelectNum] = useState(0);
    const [templateName, setTemplateName] = useState('');
    const [errors, setErrors] = useState('');
    const [templateDialogVisible, setTemplateDialogVisible] = useState(false);
    const [templateList, setTemplateList] = useState([]);
    const [curTemplate, setCurTemplate] = useState();
    const csrfId = useRef(null);
    const form = Field.useField({});
    const taskCenterUrl = taskUrl;
    const selectStatus = useMemo(() => {
        if (selectNum === exportColumns?.length) {
            return 'all';
        }
        if (selectNum && selectNum < exportColumns?.length) {
            return 'some';
        }
        return null;
    }, [selectNum, exportColumns]);
    const memoTemplates = useCallback((useDefault = false) => {
        getTemplateList(domain, metricsType, functionType).then((res) => {
            const templates = res?.tableData?.map((e) => ({
                label: e.label,
                value: e.id,
                selectClumns: e.data,
                num: e.data?.length,
                uuid: e.id,
            })) || [];
            setTemplateList(templates);
            if (templates.length) {
                useDefault && form.setValue('template', templates[0].value);
                const { template } = form.values;
                if (template &&
                    !templates.find((e) => e.value === template)) {
                    form.setValue('template', templates[0].value);
                }
            }
        });
    }, [domain, metricsType, functionType, setTemplateList, form]);
    const memoColumns = useCallback((item) => {
        getExportColumns(domain, exportMode, {
            ...item,
            processType: domain,
            metricsType,
            functionType,
        }).then((columns) => {
            const users = item.selectClumns || [];
            if (users.length) {
                setExportColumns([
                    // 模板设置的顺序
                    ...users
                        .map((e) => columns.find((o) => o.key === e))
                        .filter((e) => e),
                    // 剩余的字段
                    ...columns
                        .filter((e) => !users.includes(e.key))
                        .map((o) => ({ ...o, visible: false })),
                ]);
            }
            else {
                getUserSet(domain, exportMode === 2).then((user) => {
                    const u = (user && user.data) || [];
                    setExportColumns([
                        // 用户设置的顺序
                        ...u
                            .map((e) => columns.find((o) => o.key === e))
                            .filter((e) => e),
                        // 剩余的字段
                        ...columns
                            .filter((e) => !u.includes(e.key))
                            .map((o) => ({ ...o, visible: false })),
                    ]);
                });
            }
        });
    }, [domain, exportMode, metricsType, functionType]);
    useEffect(() => {
        if (visible) {
            const taskName = getDefaultFileName({
                exportMode,
                domain,
                domain_cn,
                functionType,
            });
            taskName &&
                form.setValues({
                    taskName,
                    template: '',
                });
            if (exportCloumnSet === true) {
                // setDsize('medium');
                // setShowExportSetting(true);
                // 查询模版列表 processType, metricsType, functionType
                memoTemplates(true);
            }
            else {
                setExportColumns([]);
                // setDsize(size === 'small' ? 'medium' : size);
                // setShowSetDiv(false);
                // setTemplateList([]);
            }
        }
    }, [
        visible,
        exportMode,
        domain,
        domain_cn,
        form,
        memoTemplates,
        exportCloumnSet,
        functionType,
    ]);
    useEffect(() => {
        const checkeds = exportColumns.filter((e) => e.visible);
        setSelectNum(checkeds.length);
    }, [exportColumns]);
    const getCsrf = () => {
        if (csrfId.current) {
            return Promise.resolve();
        }
        if (csrfToken) {
            csrfId.current = csrfToken;
            return Promise.resolve();
        }
        let origin = '';
        try {
            if (exportUrl) {
                if (exportUrl.indexOf('//') === 0) {
                    try {
                        const u = new URL(`https:${exportUrl}`);
                        origin = u.origin;
                    }
                    catch (e) {
                        //
                    }
                }
                else if (exportUrl.indexOf('http') === 0) {
                    try {
                        const u = new URL(exportUrl);
                        origin = u.origin;
                    }
                    catch (e) {
                        //
                    }
                }
                else if (exportUrl.indexOf('/') === 0) {
                    origin = location.origin;
                }
            }
            else {
                let tmpUrl = '';
                const tmpCsrfUrl = csrfUrl || `${domainEnv.getDomain('data')}/api/csrf`;
                if (tmpCsrfUrl.indexOf('https:') === 0) {
                    tmpUrl = tmpCsrfUrl;
                }
                else if (tmpCsrfUrl.indexOf('http:') === 0) {
                    tmpUrl = tmpCsrfUrl.replace('http:', 'https:');
                }
                else if (tmpCsrfUrl.indexOf('//') === 0) {
                    tmpUrl = `https:${tmpCsrfUrl}`;
                }
                else if (tmpCsrfUrl.indexOf('/') === 0) {
                    tmpUrl = `${location.origin}${tmpCsrfUrl}`;
                }
                else {
                    tmpUrl = tmpCsrfUrl;
                }
                const tmp = new URL(tmpUrl);
                origin = tmp.origin;
            }
            return wmsCnRequestV3?.getCsrfId?.((token) => {
                if (token) {
                    csrfId.current = token;
                    return Promise.resolve();
                }
                return Promise.reject();
            }, origin);
        }
        catch (e) {
            return Promise.reject();
        }
    };
    const getSelectedKeys = (recordKey, records) => {
        const selectedKeys = [];
        records.forEach((n) => {
            selectedKeys.push(n[recordKey]);
        });
        return selectedKeys;
    };
    const checkIsNeedTranslate = (transKeys, targetParam) => {
        if (transKeys && transKeys.length) {
            if (!targetParam.queryParams) {
                targetParam.queryParams = {
                    ...(targetParam.filter || {}),
                };
            }
            const { queryParams } = targetParam;
            return transKeys.some((key) => queryParams[key]);
        }
        return false;
    };
    // 针对zereport的参数转义，导出前置转关再前端调用接口完成，查询前置转换再后端查询接口完成
    const translateParaForZereport = (transKeys, targetParam, callback) => {
        // 要取这个域名的csrf，这里当前不跨域，未来如果要跨域用，这里要改，再去一次packing域名的csrf TODO
        let itemUrlhost;
        if (barCode2ItemIdUrl.startsWith('http://') ||
            barCode2ItemIdUrl.startsWith('https://')) {
            itemUrlhost = new URL(barCode2ItemIdUrl);
        }
        const func = (tk) => {
            fetch(barCode2ItemIdUrl, {
                ...requestOptions,
                method: 'post',
                body: JSON.stringify({ data: targetParam }),
                mode: 'cors',
                credentials: 'include',
                headers: {
                    'content-Type': 'application/json',
                    'x-xsrf-token': tk,
                },
            })
                .then((res) => res.json())
                .then((res) => {
                if (res?.success) {
                    // barCode2ItemIdKey barCode2ItemIdTargetKey
                    if (res.data && Object.keys(res.data).length) {
                        Object.keys(res.data).forEach((key) => {
                            // 中心化导出条件的差异处理，将barCode2ItemIdKey 转化为 barCode2ItemIdTargetKey
                            // 这里要兼容处理，线上多货库存（多货库存不是中心化导出），比如多货、交货单的商品条码转itemId, 外部单号转交货单id, 任务进度的条码、交易单号自转
                            if (barCode2ItemIdTargetKey &&
                                barCode2ItemIdKey.includes(key) &&
                                barCode2ItemIdTargetKey[barCode2ItemIdKey.indexOf(key)]) {
                                const k = barCode2ItemIdTargetKey[barCode2ItemIdKey.indexOf(key)];
                                if (k) {
                                    targetParam.queryParams[k] = res.data[key];
                                    targetParam.filter &&
                                        (targetParam.filter[k] = res.data[key]);
                                    // 删除原来的条件，保留新的，不然同时过滤肯定查不到数据
                                    if (k !== key) {
                                        delete targetParam.queryParams[key];
                                        targetParam.filter && delete targetParam.filter[key];
                                    }
                                }
                            }
                            else {
                                targetParam.queryParams[key] = res.data[key];
                            }
                        });
                    }
                }
            })
                .finally(() => {
                callback && callback(targetParam);
            });
        };
        // 开始执行 -- 当前只有库存管理页面有转移需求，不跨域，直接用csrf； 如果其他页面用，且有跨域，这里要再取csrf
        if (itemUrlhost || barCode2ItemIdUrl.indexOf('/') === 0) {
            const origin = itemUrlhost ? itemUrlhost.origin : location.origin;
            if (!(window.csrfFetingRequestMap && window.csrfFetingRequestMap[origin])) {
                // 如果没有，去取，并赋值
                window.csrfFetingRequestMap = window.csrfFetingRequestMap || {};
                window.csrfFetingRequestMap[origin] = fetch(`${origin}/api/csrf`, {
                    ...requestOptions,
                    method: 'get',
                    mode: 'cors',
                    credentials: 'include',
                    headers: {
                        'content-Type': 'application/json',
                    },
                }).then((res) => res.json());
            }
            // 如果有当前域名的CSRF,直接使用
            return window.csrfFetingRequestMap[origin]
                .then((cf) => {
                if (cf && cf.data && cf.data.token) {
                    func(cf.data.token);
                }
                else {
                    callback && callback(targetParam);
                }
            })
                .catch(() => {
                callback && callback(targetParam);
            });
        }
        return callback && callback(targetParam);
    };
    const onOk = () => {
        const queryParams = {};
        let param = {};
        form.validate((error, values) => {
            if (error) {
                return;
            }
            setLoading(true);
            const { taskName = 'unTitle', template } = values || {};
            param.taskName = taskName;
            if (customExportFn) {
                customExportFn(taskName, taskCenterUrl)
                    .then(() => {
                    setLoading(false);
                    setVisible(false);
                    // dialogInstance && dialogInstance.hide();
                })
                    .catch(() => setLoading(false));
                return;
            }
            if (Array.isArray(selectedRowKeys) && selectedRowKeys.length) {
                selectedRowKeys.forEach(() => {
                    queryParams[primaryKey] = getSelectedKeys(primaryKey, selectedRowRecords);
                });
            }
            if (rowSelectKey && rowSelect2FilterKey && selectedRowRecords.length) {
                selectedRowRecords.forEach((o) => {
                    queryParams[rowSelect2FilterKey]
                        ? queryParams[rowSelect2FilterKey]?.push(o[rowSelectKey])
                        : (queryParams[rowSelect2FilterKey] = [o[rowSelectKey]]);
                });
            }
            Object.assign(queryParams, _bindFilterData);
            if (paramsJsx && typeof paramsJsx === 'function') {
                Object.assign(param, paramsJsx(_state));
            }
            else if (params && typeof params === 'object') {
                Object.assign(param, params);
            }
            // 这里有坑，低码的函数会处理参数，可能用到这个参数
            param.exportName = taskName;
            if (progressData) {
                param = progressData(param, queryParams, paramsJsx ? paramsJsx?.(_state) : params, selectedRowRecords);
            }
            else {
                // 兼容老大宝逻辑
                param.type = params?.type === 'offline' ? 2 : 1;
                param.queryParams = queryParams;
            }
            Object.keys(param?.queryParams || {}).forEach((key) => {
                if (param?.queryParams && param?.queryParams?.[key] === '') {
                    delete param.queryParams[key];
                }
                if (param?.queryParams &&
                    typeof param?.queryParams?.[key] === 'undefined') {
                    delete param.queryParams[key];
                }
                if (param?.queryParams &&
                    Array.isArray(param?.queryParams?.[key]) &&
                    !param?.queryParams?.[key]?.length) {
                    delete param.queryParams[key];
                }
            });
            // 新版离线导出
            if (version === 'V2') {
                param.exportMode = exportMode;
                param.domain = domain;
            }
            if (exportCloumnSet) {
                param.exportMode = exportMode;
                param.domain = domain;
                // 解决参数被覆盖的问题
                param = {
                    ...param,
                    ...getExportOfflineFilter(param, props),
                };
                // 新增导出字段
                // param.frontExportFieldList = (exportColumns || [])
                //   .filter((e: SortDataProps) => e.visible)
                //   .map((e: SortDataProps) => e.key);
                const tmp = templateList.find((e) => e.value === template);
                param.frontExportFieldList = tmp && tmp.selectClumns;
                param.field = tmp && tmp.selectClumns;
                param.processType = domain;
                param.metricsType = metricsType;
                param.functionType = functionType;
            }
            else if (functionType || functionType === 0) {
                // 针对汇总导出、下钻导出，走SDK链路
                param.functionType = functionType;
            }
            // 二次预发 名称丢掉
            !param.taskName && (param.taskName = taskName);
            !param.exportName && (param.exportName = taskName);
            const doExport = (postData) => {
                fetch(exportUrl, {
                    method: 'post',
                    body: isNeedDataWrapper
                        ? JSON.stringify({
                            data: postData,
                            csrfId: csrfId.current,
                            _csrf: csrfId.current,
                        })
                        : JSON.stringify(postData),
                    mode: 'cors',
                    credentials: 'include',
                    headers: {
                        'content-Type': 'application/json',
                    },
                    ...requestOptions,
                })
                    .then((res) => res.json())
                    .then((res) => {
                    if (res?.success) {
                        setVisible(false);
                        CnMessage.show({
                            type: 'success',
                            duration: 5000,
                            content: (React.createElement("span", { className: 'task-center-notice' },
                                $i18n.get({
                                    id: 'TheTaskWasCreatedSuccessfullyClick_344111788',
                                    dm: '任务创建成功，点击到',
                                }),
                                React.createElement(CnButton, { text: true, component: 'a', href: taskCenterUrl, target: '_blank', type: 'primary', "data-autolog": 'customPath=export.action&text=\u4EFB\u52A1\u4E2D\u5FC3' }, $i18n.get({ id: 'MissionCenter', dm: '任务中心' })),
                                $i18n.get({ id: 'View', dm: '查看' }))),
                        });
                    }
                    else {
                        CnMessage.show({
                            type: 'error',
                            content: res?.error?.message ||
                                $i18n.get({ id: 'ExportFailed', dm: '导出失败' }),
                        });
                    }
                    setLoading(false);
                })
                    .catch((err) => {
                    let errorMessage = '';
                    if (err?.response) {
                        errorMessage = $i18n.get({
                            id: 'SystemExceptionErrResponseStatuserrresponsestatitext',
                            dm: '系统异常 {errResponseStatus} - {errResponseStatusText}',
                        }, {
                            errResponseStatus: err.response.status,
                            errResponseStatusText: err.response.statusText,
                        });
                    }
                    else {
                        errorMessage =
                            err.message ||
                                $i18n.get({ id: 'NetworkAnomaly', dm: '网络异常' });
                    }
                    CnMessage.show({
                        type: 'error',
                        content: errorMessage,
                    });
                })
                    .finally(() => {
                    setLoading(false);
                });
            };
            if (checkIsNeedTranslate(barCode2ItemIdKey, param)) {
                translateParaForZereport(barCode2ItemIdKey, param, (newParam) => {
                    if (!isNeedCsrf) {
                        doExport(newParam);
                        return;
                    }
                    getCsrf()
                        .then(() => doExport(newParam))
                        .catch(() => setLoading(false));
                });
            }
            else {
                if (!isNeedCsrf) {
                    doExport(param);
                    return;
                }
                getCsrf()
                    .then(() => doExport(param))
                    .catch(() => setLoading(false));
            }
        });
        return true;
    };
    const renderButtons = () => {
        if (isJump2History) {
            return (React.createElement("a", { href: taskCenterUrl, className: 'btn-task-center', target: '_blank', rel: 'noreferrer', "data-autolog": `customPath=export.action&text=任务中心&clk=true` }, $i18n.get({ id: 'MissionCenter', dm: '任务中心' })));
        }
        let isDisBtn = false; // 仅支持低码中的禁用按钮，比如选中可用(低码中写逻辑)
        if (typeof disabled === 'function') {
            isDisBtn = disabled({}, _state, selectedRowKeys, selectedRowRecords);
        }
        const button = (React.createElement(CnButton, { onClick: () => handleExport(), size: dsize, "data-hottag-name": 'cn-sc-wms-biz-download', "data-autolog": `customPath=export.action&text=${name}&clk=true`, ...(isMenuItem
                ? {
                    // component: 'a',
                    text: true,
                }
                : {}), ...(isDisBtn ? { disabled: true } : {}) }, name));
        return tipContent ? (React.createElement(CnBalloon.Tooltip, { trigger: button, align: 'r' }, tipContent)) : (button);
    };
    const handleExport = () => {
        if (preCheck && !preCheck()) {
            return;
        }
        // const defaultName = getDefaultFileName(props);
        setVisible(true);
    };
    const handleSaveTemplate = useCallback((isSaveAs) => {
        if (!templateName) {
            setErrors('该字段是必填字段');
            return false;
        }
        const sels = exportColumns
            .filter((e) => e.visible)
            .map((e) => e.key);
        if (sels.length === 0) {
            CnMessage.show({
                type: 'error',
                content: '请至少勾选一个字段',
            });
            return false;
        }
        const option = {
            processType: domain,
            metricsType,
            functionType,
            templateName,
            exportColumns: sels,
        };
        if (curTemplate && curTemplate.uuid) {
            if (isSaveAs) {
                // 另存
                const one = templateList.find((e) => e.label === templateName);
                if (one && one.uuid) {
                    setErrors('该模板名称已存在，请更换模板名称，或保存模板');
                    return false;
                }
            }
            else {
                // 编辑 -- 产品要求不能改名称
                if (templateName !== curTemplate?.label) {
                    setErrors(`该模板名称与原模板名称不一致，请使用原模板名称：${curTemplate?.label}，或另存模板`);
                    return false;
                }
                option.uuid = curTemplate.uuid;
            }
        }
        else {
            // 新增
            const one = templateList.find((e) => e.label === templateName);
            if (one && one.uuid) {
                setErrors('该模板名称已存在，请更换模板名称');
                return false;
            }
        }
        return editTemplate(option).then((e) => {
            if (e) {
                setTemplateDialogVisible(false);
                CnMessage.show({
                    type: 'success',
                    content: '操作成功',
                });
                memoTemplates();
            }
        });
    }, [
        exportColumns,
        curTemplate,
        domain,
        metricsType,
        functionType,
        templateName,
        templateList,
        memoTemplates,
    ]);
    const renderOption = (item) => {
        const removeTriggerRef = React.createRef();
        const handleRemoveClose = () => {
            removeTriggerRef.current?.click && removeTriggerRef.current.click();
        };
        return (React.createElement("span", { className: 'cn-ui-filter-save-option' },
            React.createElement("span", { className: 'option-label' },
                item.label,
                "\u00A0\u00A0",
                React.createElement("span", { style: { marginLeft: '10px' } },
                    "*\u542B",
                    item.num,
                    "\u4E2A\u5B57\u6BB5")),
            React.createElement("span", { style: { marginRight: '30px' }, onClick: (e) => e && e.stopPropagation() },
                React.createElement(CnButton, { text: true, type: 'primary', onClick: () => {
                        setTemplateName(item.label);
                        setCurTemplate(item);
                        setErrors('');
                        memoColumns(item);
                        setTemplateDialogVisible(true);
                    } }, "\u4FEE\u6539\u6A21\u677F")),
            React.createElement("span", { onClick: (e) => e && e.stopPropagation() },
                React.createElement(CnBalloon, { v2: true, align: 'tl', autoFocus: true, trigger: React.createElement("span", { ref: removeTriggerRef },
                        React.createElement(CnButton, { text: true, type: 'primary' }, "\u5220\u9664")), closable: false, triggerType: 'click' },
                    React.createElement("div", { className: 'cn-ui-filter-save-text' }, "\u786E\u5B9A\u8981\u5220\u9664\u8BE5\u6A21\u677F\u5417\uFF1F\u5220\u9664\u540E\u4E0D\u53EF\u6062\u590D"),
                    React.createElement("div", { className: 'cn-ui-filter-save-btns' },
                        React.createElement(CnButton, { type: 'primary', size: 'small', onClick: () => {
                                deleteTemplate({
                                    uuid: item.uuid,
                                    processType: domain,
                                    metricsType,
                                    functionType,
                                }).then((e) => {
                                    if (e) {
                                        memoTemplates();
                                        handleRemoveClose();
                                        CnMessage.show({
                                            type: 'success',
                                            content: '操作成功',
                                        });
                                    }
                                });
                            } }, $i18n.get({ id: 'ok', dm: '确认' })),
                        React.createElement(CnButton, { size: 'small', onClick: handleRemoveClose }, $i18n.get({ id: 'cancel', dm: '取消' })))))));
    };
    return (React.createElement("div", { style: { flexGrow: 1 } },
        React.createElement("div", { className: classnames('cn-sc-export', className) }, renderButtons()),
        React.createElement(CnDialog, { className: classnames('cn-sc-export-pop', popupClassName), visible: visible, onClose: () => setVisible(false), 
            // style={{ width: 400 }}
            size: dsize, title: $i18n.get({ id: 'ExportFileNaming', dm: '导出文件命名' }), ...container, "data-autolog": 'customPath=export.dialog&text=\u5BFC\u51FA\u547D\u540D&clk=true', footerActions: footerActions, onCancel: () => setVisible(false), onOk: onOk, okProps: {
                loading,
            } },
            React.createElement(Form, { field: form, ...{
                    style: { marginBottom: '-15px' },
                } },
                React.createElement(Form.Item, { name: 'taskName', label: $i18n.get({ id: 'ExportFileName', dm: '导出文件名' }), required: true, useLabelForErrorMessage: true },
                    React.createElement(CnInput, { name: 'taskName', placeholder: $i18n.get({
                            id: 'gxms-pc_Enter.CNTM',
                            dm: '请输入',
                        }), hasClear: true, style: { width: '100%' } })),
                exportCloumnSet && (React.createElement(Form.Item, { name: 'template', label: React.createElement("span", { onClick: (e) => e && e.stopPropagation() },
                        $i18n.get({ id: 'ExportTemplate', dm: '导出模板' }),
                        React.createElement("span", { style: { marginLeft: '15px' } },
                            React.createElement(CnButton, { text: true, type: 'primary', onClick: () => {
                                    memoTemplates();
                                    CnMessage.show({
                                        type: 'success',
                                        content: '操作成功',
                                    });
                                } }, "\u5237\u65B0\u5217\u8868")),
                        React.createElement("span", { onClick: (e) => e && e.stopPropagation(), style: { marginLeft: '15px', color: 'red' } },
                            React.createElement(CnButton, { text: true, type: 'primary', onClick: () => {
                                    setTemplateName('');
                                    setErrors('');
                                    memoColumns({});
                                    setCurTemplate(undefined);
                                    setTemplateDialogVisible(true);
                                } }, "\u65B0\u589E\u6A21\u677F"))), required: true, requiredMessage: '导出模板是必填字段' },
                    React.createElement(CnAsyncSelect, { name: 'template', dataSource: templateList, itemRender: (item) => renderOption(item), showSearch: true, hasClear: true, 
                        // visible={templateOptioinVisible}
                        // onVisibleChange={setTemplateOptioinVisible}
                        // onFocus={() => setTemplateOptioinVisible(true)}
                        style: { width: '100%' } }))))),
        React.createElement(CnDialog, { className: 'cn-sc-export-pop', visible: templateDialogVisible, onClose: () => setTemplateDialogVisible(false), size: 'large', title: `${domain_cn}${setName}字段配置`, "data-autolog": 'customPath=export.dialog.step&text=\u7ED9\u5BFC\u51FA\u5B57\u6BB5\u914D\u7F6E\u6A21\u677F&clk=true', footerActions: ['ok'], onCancel: () => {
                // 这里是另存为
                if (curTemplate) {
                    handleSaveTemplate(true);
                }
                else {
                    setTemplateDialogVisible(false);
                }
            }, onOk: () => handleSaveTemplate(false), okProps: {
                children: '保存',
            }, cancelProps: {
                children: curTemplate ? '另存' : '取消',
                type: curTemplate ? 'primary' : 'normal',
            }, footer: React.createElement("span", null,
                React.createElement("div", { className: 'cn-sc-export-pop-selectall' },
                    React.createElement(CnCheckbox, { indeterminate: selectStatus === 'some', checked: selectStatus === 'all', onChange: () => setExportColumns((prev) => {
                            return prev?.map?.((item) => {
                                return {
                                    ...item,
                                    visible: selectStatus !== 'all',
                                };
                            });
                        }) }, "\u5168\u9009")),
                curTemplate && (React.createElement(CnButton, { type: 'normal', onClick: () => setTemplateDialogVisible(false) }, "\u53D6\u6D88"))) },
            React.createElement(CnFormItem, { required: true, title: (React.createElement("span", { style: { fontSize: '16px' } }, curTemplate ? '修改模板' : '新增模板')), tip: '\u6A21\u677F\u4E2D\u547D\u540D\u4E0D\u80FD\u91CD\u590D', errors: [errors], labelCol: {
                    fixedSpan: 6,
                }, wrapperCol: {
                    span: 14,
                } },
                React.createElement("span", { style: {
                        display: 'inline-flex',
                        width: '100%',
                        alignItems: 'center',
                    } },
                    React.createElement("span", { style: {
                            marginRight: '30px',
                            color: '#000',
                        } },
                        React.createElement(CnInput, { name: 'templateName', placeholder: '\u6A21\u677F\u540D\u79F0', style: { width: 300 }, value: templateName, hasClear: true, onChange: (value) => {
                                setTemplateName(value);
                                if (value) {
                                    setErrors('');
                                }
                            }, trim: true })),
                    React.createElement(CnMessage, { type: 'warning', className: 'export-setting' },
                        React.createElement("span", null, "\u52FE\u9009\u5B57\u6BB5\u8D8A\u591A\uFF0C\u5355\u6B21\u5BFC\u51FA\u65F6\u95F4\u8D8A\u957F")))),
            React.createElement(CnFormItem, { style: { maxHeight: '380px', overflowY: 'auto' } },
                React.createElement(Sortable, { adjustScale: true, 
                    // onChange={sortableOnChange}
                    // Container={(p) => <GridContainer {...p} />}
                    handle: true, wrapperStyle: (p) => ({
                        ...p,
                        width: 155.5,
                        height: 35,
                    }), items: exportColumns, setItems: setExportColumns })))));
}
ExportNew.displayName = componentName;
export { ExportNew };
